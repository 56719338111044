import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import 'bootstrap/dist/css/bootstrap.min.css';

const AutocompleteDropdown = () => {
  const options = [
    'Surya', 'Madhav', 'Karthik', 'Vinay',
 'Manajo', 'Karunakar'
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = options.filter(option =>
      option.toLowerCase().includes(searchTerm)
    );
    setFilteredOptions(filtered);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant='' className="fr-toggle px-4 py-3 btn btn-light border text-start" id="dropdown-basic">
        You (Admin)
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <FormControl
          autoFocus
          placeholder="Filter by Name"
          onChange={handleSearchChange}
          value={searchTerm}
          className="mx-3 my-2 w-auto"
        />
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <Dropdown.Item className="list-group-item px-4 py-2" key={index} href={`#/${option.toLowerCase()}`}>
              {option}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item disabled>No recruiter found with that name.</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AutocompleteDropdown;