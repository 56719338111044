import React from 'react';
import { Link } from 'react-router-dom';

const ManagePlan = () => {
  return (
<section class="bsb-pricing-2 bg-light js-pg-hg py-xl-8">
  <div class="container">
  <div className="d-lg-flex d-sm-block flex-row align-items-center justify-content-between">
          <div className='js-mn-title flex-grow-1'>
            <h2 className='fw-bold pb-2'>Subscriptions</h2>
            <p>Manage your jobsco subscriptions</p>
          </div>
          <div>
            <label>Your Current Plan</label>
            <h4 className='fs-4 text-primary pt-2'>Basic</h4>
          </div>
        </div>
        <hr class="w-100 mx-auto mb-5 mb-xl-12 border-dark-subtle" />
  </div>

  <div class="container">
    <div class="row gy-5 gy-lg-0 gx-xl-5 justify-content-md-center">
      <div class="col-12  p-lg-0 col-lg-3">
        <div class="card border-0 rounded-4 border-bottom z-index-1 shadow-lg">
        <div class="card-header rounded-top-4 color-1 p-5">
        <h2 class="h5 mb-2">Basic Plan</h2>
            <h4 class="display-6 fw-bold text-primary mb-0">1.5 Lacs</h4>
            <p class="text-primary mb-0">Rupees / Year</p>
            </div>
          <div class="card-body p-4 p-xxl-5">
            <ul class="list-group list-group-flush mb-4">
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>10</strong> User Login Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>2k</strong> Job Posting Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span><strong>70K</strong>  CV Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Employer Profile</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Email Notifications</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Schedule Reminders </span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Comprehensive Reports</span>
              </li>
            </ul>
            <Link to="/pricingform" class="btn bsb-btn-xl py-3 px-4 btn-secondary" disabled>Current Plan</Link>
          </div>
        </div>
      </div>
      <div class="col-12 p-lg-0 col-lg-3">
        <div class="card border-0 rounded-4 border-bottom shadow-sm bsb-pricing-popular">
        <div class="card-header rounded-top-4 color-2 p-5">
        <h2 class="h5 mb-2">Business Plan</h2>
        <h4 class="display-6 fw-bold text-primary mb-0">2.5 Lacs</h4>
        <p class="text-primary mb-0">Rupees / Year</p>
            </div>
          <div class="card-body p-4 p-xxl-5">
            <ul class="list-group list-group-flush mb-4">
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>20</strong> User Login Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>15k</strong> Job Posting Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span><strong>2.5 L</strong>  CV Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Employer Profile</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Email Notifications</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Schedule Reminders </span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Comprehensive Reports</span>
              </li>
            </ul>
            <a href="#!" class="btn bsb-btn-xl py-3 px-4 btn-primary">Upgrade</a>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 col-lg-3">
        <div class="card border-0 rounded-4 border-bottom shadow-sm">
        <div class="card-header rounded-top-4 color-3 p-5">
        <h2 class="h5 mb-2">Premium Plan</h2>
        <h4 class="display-6 fw-bold text-primary mb-0">5 Lacs</h4>
        <p class="text-primary mb-0">Rupees / Year</p>
            </div>
          <div class="card-body p-3 p-xxl-5">
            <ul class="list-group list-group-flush mb-4">
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>50</strong> User Login Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">
                <span><strong>25k</strong> Job Posting Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span><strong>5L</strong>  CV Access</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Employer Profile</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Email Notifications</span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Schedule Reminders </span>
              </li>
              <li class="list-group-item mb-2 border-0 px-0">

                <span>Comprehensive Reports</span>
              </li>
            </ul>
            <a href="#!" class="btn bsb-btn-xl py-3 px-4 btn-primary">Upgrade</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  );
};

export default ManagePlan;
