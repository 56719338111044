import React from 'react';
import './Footer.scss'; // Import a CSS file for styling (optional)

const Footer = () => {
  return (
    <footer class="bg-white">
    <div class="py-3 py-md-4 py-xl-5">
      <div class="container">
        <div class="row g-3 align-items-center">
          <div class="col-12 col-lg-6 order-0 order-lg-1">
            <div class="link-wrapper">
              <ul class="m-0 list-unstyled d-flex justify-content-center justify-content-lg-end gap-2 gap-md-3">
                <li>
                  <a href="#!" class="link-underline-opacity-0 link-opacity-75-hover link-underline-opacity-100-hover link-offset-1 link-secondary fs-8 d-flex align-items-center pe-2 pe-md-3 bsb-sep bsb-sep-border">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#!" class="link-underline-opacity-0 link-opacity-75-hover link-underline-opacity-100-hover link-offset-1 link-secondary fs-8 d-flex align-items-center pe-2 pe-md-3 bsb-sep bsb-sep-border">
                    Privacy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-lg-6 order-1 order-lg-0">
            <div class="copyright-wrapper d-block mb-1 fs-8 text-center text-lg-start">
              &copy; 2024. Jobsco. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
</footer>
  );
};

export default Footer;
