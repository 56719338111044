import React, { useState } from 'react';
import '../DropdownMenu/DropdownMenu.scss'; // For custom styling
import { CiMenuKebab } from "react-icons/ci";

const DropdownMenu = ({ options, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    return (
        <div className="js-dropdown">
            <button className="btn btn-link m-0 p-0 js-dropdown-button" onClick={toggleDropdown}>
            <CiMenuKebab />
            </button>
            {isOpen && (
                <ul className="js-dropdown-menu">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className="js-dropdown-item border-bottom small"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DropdownMenu;