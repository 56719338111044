import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import Messages from '../Messages/Messages';
import SavedProfile from '../SavedProfile/SavedProfile';
import SearchHistory from '../SearchHistory/SearchHistory';
import Notification from '../Notification/Notification';
import '../HeaderMenu/HeaderMenu.scss';
import { MdNotificationsNone } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { LuMessageSquare } from "react-icons/lu";
import { FaRegBookmark } from "react-icons/fa";
import { MdHistory } from "react-icons/md";
import { Tooltip } from 'bootstrap';
import { LuCalendarClock } from "react-icons/lu";

const HeaderLogin = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [isSavedOpen, setIsSavedOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const closeMenu = () => setIsMenuOpen(false);

    const toggleMessage = () => setIsMessageOpen(!isMessageOpen);
    const closeMessage = () => setIsMessageOpen(false);

    const toggleSaved = () => setIsSavedOpen(!isSavedOpen);
    const closeSaved = () => setIsSavedOpen(false);

    const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
    const closeSearch = () => setIsSearchOpen(false);

    const toggleNotification = () => setIsNotificationOpen(!isNotificationOpen);
    const closeNotification = () => setIsNotificationOpen(false);

    const buttonRefs = useRef([]);

    useEffect(() => {
      // Initialize tooltips for all buttons
      const tooltips = buttonRefs.current.map((ref) => {
        if (ref) {
          return new Tooltip(ref, {
            placement: 'bottom', // Set default placement, can be overridden by individual buttons
            trigger: 'hover', // Show tooltip on hover
          });
        }
        return null;
      });

      // Cleanup function to dispose of all tooltips on unmount
      return () => {
        tooltips.forEach((tooltip) => tooltip && tooltip.dispose());
      };
    }, []);

    return (
        <><nav class="navbar fixed-top py-4 navbar-light bg-white navbar-expand-lg">
            <div class="container">
                <Link to="/" className="navbar-brand fs-3 fw-bold">Jobsco</Link>
                <div className="d-flex js-head-frm">


                <input
                    type="text"
                    value=""

                    placeholder="Keywords..."
                    className="form-control rounded-start"
                />
                <Link to="/profilelist"
                    className="btn btn-primary px-4 rounded-end"
                >
                    Search
                </Link>
            </div>
                <div className='d-flex js-lg-link'>

                <button
        className="btn px-2 py-2 me-4 fs-2 icon-clr"
        ref={(el) => (buttonRefs.current[0] = el)} // Assign ref to each button
        title="Search History"
        data-bs-toggle="tooltip"
        onClick={toggleSearch}
      >
        <MdHistory />
      </button>

      <button
        className="btn px-2 py-2 me-4 fs-3 icon-clr"
        ref={(el) => (buttonRefs.current[1] = el)} // Assign ref to each button
        title="Saved Profile"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        onClick={toggleSaved}
      >
        <FaRegBookmark />
      </button>


      <button
        className="btn px-2 py-2 me-4 fs-2 icon-clr"
        ref={(el) => (buttonRefs.current[2] = el)} // Assign ref to each button
        title="Messages"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        onClick={toggleMessage}
      >
        <LuMessageSquare />
      </button>
      <button
        className="btn px-2 py-2 me-4 fs-2 icon-clr"
        ref={(el) => (buttonRefs.current[3] = el)} // Assign ref to each button
        title="Reminders"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        onClick={toggleNotification}
      >
  <LuCalendarClock />
      </button>
      <button
        className="btn px-2 py-2 fs-2 icon-clr"
        ref={(el) => (buttonRefs.current[4] = el)} // Assign ref to each button
        title="User Profile"
        data-bs-toggle="tooltip"
        onClick={toggleMenu}
      >
        <FaRegUserCircle />
      </button>

                </div>

            </div>
        </nav><div><HeaderMenu isOpen={isMenuOpen} onClose={closeMenu} /> <SavedProfile isOpen={isSavedOpen} onClose={closeSaved} /> <Notification isOpen={isNotificationOpen} onClose={closeNotification} /> <SearchHistory isOpen={isSearchOpen} onClose={closeSearch} /> <Messages isOpen={isMessageOpen} onClose={closeMessage} /></div></>
    );
};

export default HeaderLogin;
