// src/components/DatePickerComponent.js
import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdDateRange } from "react-icons/md";

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className="input-group">
    <input
      type="text"
      className="form-control"
      onClick={onClick}
      value={value}
      readOnly
      ref={ref} // Attach ref to the input element
      disabled />
    <button
      className="btn btn-outline-secondary border position-absolute end-0 top-0 h-100"
      type="button"
      onClick={onClick} // Trigger date picker
    >
      <MdDateRange />
    </button>
  </div>
));

const DatePickerComponent = () => {
  const [startDate, setStartDate] = useState(new Date());
  const inputRef = useRef(null);

  return (
    <div className="mb-3 position-relative">
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        customInput={<CustomInput ref={inputRef} />} // Use custom input with ref
        dateFormat="MMMM d, yyyy"
      />
    </div>
  );
};

export default DatePickerComponent;