import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Tab, Tabs, Form, Button, Container } from 'react-bootstrap';
import { IoCheckmarkCircle } from "react-icons/io5";

function AuthTabs() {
    const [role, setRole] = useState('company'); // default role
    const [isRegistered, setIsRegistered] = useState(false); // Track registration status

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleRegister = (event) => {
        event.preventDefault();
        // Simulate registration process
        setIsRegistered(true);
    };

    return (
        <Container className='card p-5 shadow rounded-4'>
            <Tabs id="auth-tabs" className="mb-0">
                <Tab defaultActiveKey="signup" eventKey="signup" title="Signup as Employer">
                    {isRegistered ? (
                        <div className="text-center p-4 mt-4">
            <Link to="/create-password" ><IoCheckmarkCircle className='text-success display-1 mb-2' /></Link>
   <h4 className='text-success pb-2'>Great! You have successfully signed up for JobsCo</h4>
   <p>We've sent you an activation link to confirm your email address and set your password. Please check your inbox.</p>
                        </div>
                    ) : (
                        <Form onSubmit={handleRegister}>
                            <Form.Group className="my-4 d-flex">
                                <Form.Check
                                    type="radio"
                                    className="me-4"
                                    label="Company"
                                    value="company"
                                    checked={role === 'company'}
                                    onChange={handleRoleChange}
                                    name="role"
                                    id="roleCompany"
                                />
                                <Form.Check
                                    type="radio"
                                    label="Consultant"
                                    value="consultant"
                                    checked={role === 'consultant'}
                                    onChange={handleRoleChange}
                                    name="role"
                                    id="roleConsultant"
                                />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formSignupName">
                                <Form.Control type="text" placeholder="Enter Full Name" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formSignupEmail">
                                <Form.Control type="email" placeholder="Enter Your Email" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formSignupPassword">
                                <Form.Control type="text" placeholder="Company Name" />
                            </Form.Group>

                            <Button type="submit" variant="primary" className='btn w-100 btn-primary btn-lg py-3 px-5 mt-3'>
                                Register
                            </Button>
                        </Form>
                    )}
                </Tab>
                <Tab eventKey="login" title="Login">
                    <Form className='mt-4 pt-3'>

                        <Form.Group className="mb-4" controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Enter email address" />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="formBasicPassword">
                            <Form.Control type="password" placeholder="Password" />
                        </Form.Group>

                        <Link to="/create-password" variant="primary" className='btn w-100 btn-primary btn-lg py-3 px-5 mt-3'>
                            Login
                        </Link>
                       <div>
                        <Link to="/" className="pt-3 mt-2 text-decoration-none text-primary" type="submit">Forgot Password ?</Link>
                        </div>
                    </Form>
                </Tab>
            </Tabs>
        </Container>
    );
}

export default AuthTabs;
