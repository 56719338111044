import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { NavLink } from 'react-router-dom';
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineDashboard, MdBookmarkBorder, MdOutlineSubscriptions, MdOutlinePayments, MdLogout } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { RiUserStarLine } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import '../HeaderMenu/HeaderMenu.scss';

const HeaderMenu = ({ isOpen, onClose }) => {
    const handleItemClick = () => {
        onClose(); // Close the menu when an item is clicked
    };

    return (
        <Menu className='menubar' right isOpen={isOpen} onClose={onClose}>
            <div className='container'>
                <div className="row usrprofile p-4 border-bottom mb-4">
                    <div className="col-auto p-3 fw-bold text-dark">
                        <FaUserCircle />
                    </div>
                    <div className="col-9 p-3 px-0">
                        <h5 className='p-0 m-0 ms-2 fw-semibold'>Surya</h5>
                        <label className='fs-6 ms-2 fw-normal'>Recruiter</label>
                    </div>
                </div>
            </div>

            <ul className="list-group pt-4 ps-4 border-0">
                <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
                    <NavLink
                        to="/dashboard"
                        className={({ isActive }) => isActive ? 'ps-3 link-dark text-decoration-none active' : 'ps-3 link-dark text-decoration-none'}
                        onClick={handleItemClick}
                    >
                        {({ isActive }) => (
                            <>
                                <MdOutlineDashboard className={isActive ? 'icon-clr active' : 'icon-clr'} />
                                <span className='ps-3'>Dashboard</span>
                            </>
                        )}
                    </NavLink>
                </li>
                <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
                    <NavLink
                        to="/jobposts"
                        className={({ isActive }) => isActive ? 'ps-3 link-dark text-decoration-none active' : 'ps-3 link-dark text-decoration-none'}
                        onClick={handleItemClick}
                    >
                        {({ isActive }) => (
                            <>
                                <MdOutlinePayments className={isActive ? 'icon-clr active' : 'icon-clr'} />
                                <span className='ps-3'>Posted Jobs</span>
                            </>
                        )}
                    </NavLink>
                </li>
                <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
                    <NavLink
                        to="/postjob"
                        className={({ isActive }) => isActive ? 'ps-3 link-dark text-decoration-none active' : 'ps-3 link-dark text-decoration-none'}
                        onClick={handleItemClick}
                    >
                        {({ isActive }) => (
                            <>
                                <CiEdit className={isActive ? 'icon-clr active' : 'icon-clr'} />
                                <span className='ps-3'>Post a Job</span>
                            </>
                        )}
                    </NavLink>
                </li>
                <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
                    <NavLink
                        to="/savedprofile"
                        className={({ isActive }) => isActive ? 'ps-3 link-dark text-decoration-none active' : 'ps-3 link-dark text-decoration-none'}
                        onClick={handleItemClick}
                    >
                        {({ isActive }) => (
                            <>
                                <MdBookmarkBorder className={isActive ? 'icon-clr active' : 'icon-clr'} />
                                <span className='ps-3'>Saved Profiles</span>
                            </>
                        )}
                    </NavLink>
                </li>
                <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
                    <NavLink
                        to="/accoutsettings"
                        className={({ isActive }) => isActive ? 'ps-3 link-dark text-decoration-none active' : 'ps-3 link-dark text-decoration-none'}
                        onClick={handleItemClick}
                    >
                        {({ isActive }) => (
                            <>
                                <IoSettingsOutline className={isActive ? 'icon-clr active' : 'icon-clr'} />
                                <span className='ps-3'>Account Settings</span>
                            </>
                        )}
                    </NavLink>
                </li>
                <li className="list-group-item px-3 py-3 border-0 d-flex align-items-center">
                    <NavLink
                        to="/"
                        className={({ isActive }) => isActive ? 'ps-3 link-dark text-decoration-none active' : 'ps-3 link-dark text-decoration-none'}
                        onClick={handleItemClick}
                    >
                        {({ isActive }) => (
                            <>
                                <MdLogout className={isActive ? 'icon-clr active' : 'icon-clr'} />
                                <span className='ps-3'>Logout</span>
                            </>
                        )}
                    </NavLink>
                </li>
            </ul>
        </Menu>
    );
};

export default HeaderMenu;
