import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LuUsers2 } from "react-icons/lu";
import { MdModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";

const ManageRecruiter = () => {
  const [recruiters, setRecruiters] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cvNumber, setCvNumber] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errors, setErrors] = useState({});

  const validateFields = () => {
      const newErrors = {};

      if (!name.trim()) {
          newErrors.name = "Name is required.";
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email.trim()) {
          newErrors.email = "Email is required.";
      } else if (!emailPattern.test(email)) {
          newErrors.email = "Please enter a valid email address.";
      }

      if (!cvNumber.trim()) {
          newErrors.cvNumber = "CV Number is required.";
      } else if (!/^\d{1,5}$/.test(cvNumber)) {
          newErrors.cvNumber = "CV Number must be a number with up to 5 digits.";
      }

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };

  const addRecruiter = () => {
    if (validateFields()) {
        const newRecruiter = { name, email, cvNumber };
        setRecruiters([...recruiters, newRecruiter]);
        resetForm();
    }
};

const editRecruiter = index => {
    setIsEditing(true);
    setEditIndex(index);
    setName(recruiters[index].name);
    setEmail(recruiters[index].email);
    setCvNumber(recruiters[index].cvNumber);
};

const updateRecruiter = () => {
    if (validateFields()) {
        const updatedRecruiters = recruiters.map((recruiter, index) =>
            index === editIndex ? { name, email, cvNumber } : recruiter
        );
        setRecruiters(updatedRecruiters);
        resetForm();
    }
};

const deleteRecruiter = index => {
    setRecruiters(recruiters.filter((_, i) => i !== index));
};

const resetForm = () => {
    setName('');
    setEmail('');
    setCvNumber('');
    setIsEditing(false);
    setEditIndex(null);
    setErrors({});
};

const handleCvNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Ensure only digits are entered
        setCvNumber(value);
    }
};

  return (
    <div className="js-pg-hg">
      <div className="container">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className='js-mn-title flex-grow-1 mt-0'>
            <h2 className='fw-bold pb-2'>Manage Recruiters</h2>
            <p>Add, Edit & Delete Recruiters</p>
          </div>
        </div>
        <div class="card rounded-4 mt-4">
          <div class="card-header rounded-top-4 border-bottom py-4 bg-white p-4 d-lg-flex d-sm-block justify-content-between">
            <div className='px-3 py-2'>
              <h5 class="fw-bold card-title">Create New Recruiter</h5>
              <p class="card-text">Please fill out the information below to invite a recruiter.</p>
            </div>
            <div>
              <div className='px-2 py-2'>
                <button className="btn px-4 py-3 btn-outline-primary">Bulk Upload</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div className="d-xl-flex d-lg-block d-sm-block flex-row align-items-start justify-content-start">
              <div class="my-4 px-3 flex-grow-1">

                <label for="" class="form-label">Name</label>
                <input class="form-control" type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
                {errors.name && <p className='error'>{errors.name}</p>}
              </div>
              <div class="my-4 px-3 flex-grow-1">
                <label for="" class="form-label">Email</label>
                <input type="email" class="form-control" id="" placeholder="Enter email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                {errors.email && <p className='error'>{errors.email}</p>}
              </div>
              <div class="my-4 px-3 flex-grow-1">
                <label for="" class="form-label">Set Resumes Limits</label>
                <input class="form-control" type="text" placeholder="Enter resumes limits" value={cvNumber} onChange={handleCvNumberChange} maxLength={5} />
                {errors.cvNumber && <p className='error'>{errors.cvNumber}</p>}
              </div>
              <div class="mb-4 px-3 mt-5">
                <label for="" class="form-label">&nbsp;</label>
                {isEditing ? (
                  <button className="btn px-4 py-3 btn-primary" onClick={updateRecruiter}>Update Recruiter</button>
                ) : (
                  <button className="btn px-4 py-3 btn-primary" onClick={addRecruiter}>Add Recruiter</button>
                )}
                <button className="btn px-4 ms-4 py-3 btn-outline-primary" onClick={resetForm}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div className='row my-5'>

          <h3>Recruiters</h3>
          {recruiters.length === 0 ? (
            <><div className='col-12 jsc-nodata text-center'><hr className='pt-5' /><div className='my-3'><LuUsers2 /></div><h5>No Recruiters Found</h5><p>Please fill out the form above to invite recruiters.</p> <Link to="/dashboard" class="btn mt-3 px-5 py-3 btn-outline-primary" href="#">Skip to Dashboard</Link></div></>

          ) : (
            <><div class="table-responsive"><table className="table mt-3">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Resumes Number</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {recruiters.map((recruiter, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{recruiter.name}</td>
                      <td>{recruiter.email}</td>
                      <td>{recruiter.cvNumber}</td>
                      <td className='align-right'>
                        <button className='btn float-end fs-5 btn-default btn-link' onClick={() => editRecruiter(index)}><MdModeEdit /></button>
                        <button className='btn float-end fs-5 btn-link' onClick={() => deleteRecruiter(index)}><MdDeleteOutline /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            <div className='col-12'><Link to="/dashboard" class="btn mt-3 px-5 py-3 btn-outline-primary">Skip to Dashboard</Link></div></>
          )}

        </div>
      </div>
    </div>

  );
};

export default ManageRecruiter;
