import React from 'react';

const CardItem = ({ cardDetails }) => {
  const { title, description, imgUrl, className } = cardDetails;

  return (
    <div className="col-md-3 mb-4">
      <div className={`${className} border m-2 text-center rounded-4`}>
        {typeof imgUrl === 'string' ? (
          <img className="standard-icon" width={'40px'} height={'40px'} src={imgUrl} alt={title} />
        ) : (
          imgUrl // Render the imgUrl directly if it's a Font Awesome icon
        )}
        <div className="card-body">
          <p className="card-title pt-3 fs-6">{title}</p>
          <small className="card-text">{description}</small>
        </div>
      </div>
    </div>
  );
};

export default CardItem;