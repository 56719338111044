import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import JobCreationForm from '../JobCreationForm/JobCreationForm';
import AutocompleteDropdown from '../AutocompleteDropdown/AutocompleteDropdown';
import { jobPosts } from '../ActivePost/ActivePost';

const EditPostJob = () => {
  const { id } = useParams();
  const job = jobPosts.find((p) => p.id === parseInt(id, 10));
  return (

    <div className="js-pg-hg">
      <div className="container">
      <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/jobposts" className='text-primary text-decoration-none'>Job Posts</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            UX Architect
            </li>
          </ol>
        </nav>
        <div className="d-lg-flex d-sm-block flex-row align-items-center justify-content-between">
          <div className='js-mn-title flex-grow-1'>
            <h2 className='fw-bold pb-2'>Edit Job Post<span class="badge ms-2 bg-light text-dark">Super Admin</span></h2>
            <p>Edit Job Post</p>
          </div>
        </div>
      </div>
      <JobCreationForm />
    </div>
  );
};

export default EditPostJob;
