import React, { useState } from 'react';
import '../DropdownReminder/DropdownReminder.scss'; // For custom styling
import { LuCalendarClock } from "react-icons/lu";
import { Modal, Button, Form } from 'react-bootstrap'; // Ensure React-Bootstrap is installed

const DropdownReminder = () => {
    const [isOpen, setIsOpen] = useState(false); // Dropdown state
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
    const [selectedOption, setSelectedOption] = useState(null);

    // Dropdown options
    const options = [
        { value: 'call_later', label: 'Call Later' },
        { value: 'interview_follow_up', label: 'Interview Follow up' },
        { value: 'sending_jd', label: 'Sending JD' },
        { value: 'other_task', label: 'Other Task' }
    ];

    const toggleDropdown = () => setIsOpen(!isOpen);
    const toggleModal = () => setIsModalOpen(!isModalOpen); // Toggle modal visibility

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        toggleModal(); // Open modal on option select
    };


    return (
        <div className="js-dropdown">
            {/* Dropdown button */}
            <button className="btn fs-4 ms-3 p-0 m-0 icon-clr" onClick={toggleDropdown}>
                <LuCalendarClock />
            </button>

            {/* Dropdown menu */}
            {isOpen && (
                <ul className="js-dropdown-menu">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className="js-dropdown-item border-bottom small"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}

            {/* Modal with form */}
            <Modal show={isModalOpen} onHide={toggleModal}>
                <Modal.Header  className="px-4" closeButton>
                    <Modal.Title className="fs-5">Reminder Form</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <Form>
                    <Form.Group className="mb-3">
                            <Form.Label>Selected Task</Form.Label>
                            <Form.Control
                                type="text"
                                value={selectedOption ? selectedOption.label : ''}
                                readOnly
                            disabled />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" value="xyz@gmail.com" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formReminderDate">
                            <Form.Label>Reminder Date</Form.Label>
                            <Form.Control type="date" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formReminderTime">
                            <Form.Label>Reminder Time</Form.Label>
                            <Form.Control type="time" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="p-4">
                    <Button variant="secondary" className="p-2 px-3" onClick={toggleModal}>
                        Close
                    </Button>
                    <Button variant="primary" className="p-2 ms-3 px-3" onClick={() => { /* Handle form submit */ }}>
                        Save Reminder
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DropdownReminder;