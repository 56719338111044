import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosSearch } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { IoMdClose } from "react-icons/io";


const SearchFilter = () => {
  const [experienceRange, setExperienceRange] = useState([0, 50]);

  const handleSliderChange = (value) => {
    setExperienceRange(value);
  };


  const [isOpen, setIsOpen] = useState(false);


  const toggleCollapse = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.toggle('show');
    }
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };


  const employmenttype = [
    'Full Time',
    'Part Time',
    'Internship',
    'Freelance',
    'Remote',
    'Co-Founder',
    'Contract',
    'Remote'
  ];


  const experiencelevel = [
    'Senior Level',
    'Entry Level',
    'Mid Level',
    'Student Level',
    'Directors',
    'Co-Founder'
  ];

  const locations = [
    'Hyderabad',
    'Bengaluru',
    'Delhi / NCR',
    'Pune',
    'Mumbai',
    'Chennai',
    'New Delhi',
    'Noida',
    'Kolkata',
    'Ahmedabad',
  ];

  const salaries = [
    '15 - 25 Lakhs (8431)',
    '25 - 5 Lakhs (2735)',
    '50 - 75 Lakhs (240)',
    '75 - 100 Lakhs (57)'
  ];

  const [selectedLocations, setSelectedLocations] = useState([]);

  const [selectedEmploymenttypes, setSelectedEmploymenttypes] = useState([]);


  const [selectedExperiencelevel, setSelectedExperiencelevel] = useState([]);


  const [selectedSalary, setSelectedSalary] = useState([]);


  const handleLocationChange = (e) => {
    const location = e.target.value;
    setSelectedLocations((prevSelected) =>
      prevSelected.includes(location)
        ? prevSelected.filter(item => item !== location)
        : [...prevSelected, location]
    );
  };



  const handleEmploymentChange = (e) => {
    const employmenttype = e.target.value;
    setSelectedEmploymenttypes((prevSelected) =>
      prevSelected.includes(employmenttype)
        ? prevSelected.filter(item => item !== employmenttype)
        : [...prevSelected, employmenttype]
    );
  };


  const handleExperienceChange = (e) => {
    const experiencelevel = e.target.value;
    setSelectedExperiencelevel((prevSelected) =>
      prevSelected.includes(experiencelevel)
        ? prevSelected.filter(item => item !== experiencelevel)
        : [...prevSelected, experiencelevel]
    );
  };


  const handleSalaryChange = (e) => {
    const salary = e.target.value;
    setSelectedSalary((prevSelected) =>
      prevSelected.includes(salary)
        ? prevSelected.filter(item => item !== salary)
        : [...prevSelected, salary]
    );
  };


  return (
    <>
    <h3 className='fs-4 text-black-50'><span className='fs-4 text-body'>158 profiles have been found.</span></h3>
    <div class="card shadow-sm d-flex mt-5 bx-center border-0 p-4">
      <div class="card-header">
        <h4 className='fs-5 fw-medium border-0'>Keywords</h4>
      </div>
      <div class="card-body">
        <div class="input-group">
          <input class="form-control form-control m-0" type="password" placeholder="Search by Keyword" value="" />
          <button type="button" class="toggle-icon bg-transparent fs-4 icon-clr border-start-0 input-group-text">
            <IoIosSearch />
          </button>
        </div>
        <span class="badge badge-pill me-1 mt-3 badge-light">SQL <button className='border-0 text-primary p-0 bg-transparent vertical-bottom'><IoMdClose /></button></span>    <span class="badge badge-pill me-1 mt-3 badge-light">AWS <button className='border-0 text-primary p-0 bg-transparent vertical-bottom'><IoMdClose /></button></span>
      </div>
    </div>


    <div class="card shadow-sm mt-4 bx-center border-0 p-4">
        <div class="card-header">
        <h4 className='fs-5 fw-medium border-0'>Experience</h4>
      </div>
      <div class="card-body">
      <Slider
        range
        min={0}
        max={50}
        value={experienceRange}
        onChange={handleSliderChange}
      />
      <div className="d-flex justify-content-between mt-2">
        <div>Min: {experienceRange[0]} yrs</div>
        <div>Max: {experienceRange[1]} yrs</div>
      </div>
      </div>
    </div>


    <div class="card shadow-sm mt-4 bx-center border-0 p-4">
        <div class="card-header">
          <button
            className="btn w-100 btn-block text-start py-0 px-0 mx-0 fw-medium fs-5 border-0"
            type="button"
            onClick={() => toggleCollapse('collapseExample2')}
            aria-expanded={isOpen}
            aria-controls="collapseExample2"
          >
            Locations

            <span className="ms-2 float-end">
              {isOpen ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </span>
          </button>
        </div>
        <div className="card-body collapse" id="collapseExample2">

        {locations.map((location, index) => (
          <div className="form-check mb-3" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`location-${index}`}
              value={location}
              checked={selectedLocations.includes(location)}
              onChange={handleLocationChange}
            />
            <label className="form-check-label ms-2" htmlFor={`location-${index}`}>
              {location}
            </label>
          </div>
        ))}
      </div>
      </div>


      <div class="card shadow-sm mt-4 bx-center border-0 p-4">
        <div class="card-header">
          <button
            className="btn w-100 btn-block text-start py-0 px-0 mx-0 fw-medium fs-5 border-0"
            type="button"
            onClick={() => toggleCollapse('collapseExample3')}
            aria-expanded={isOpen}
            aria-controls="collapseExample3"
          >
            Package

            <span className="ms-2 float-end">
              {isOpen ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </span>
          </button>
        </div>
        <div className="card-body collapse" id="collapseExample3">

        {salaries.map((salary, index) => (
          <div className="form-check mb-3" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`salary-${index}`}
              value={salary}
              checked={selectedSalary.includes(salary)}
              onChange={handleSalaryChange}
            />
            <label className="form-check-label ms-2" htmlFor={`salary-${index}`}>
              {salary}
            </label>
          </div>
        ))}
      </div>
      </div>


    <div class="card shadow-sm mt-4 bx-center border-0 p-4">
        <div class="card-header">
          <button
            className="btn w-100 btn-block text-start py-0 px-0 mx-0 fw-medium fs-5 border-0"
            type="button"
            onClick={() => toggleCollapse('collapseExample12')}
            aria-expanded={isOpen}
            aria-controls="collapseExample2"
          >
            Type Of Employment

            <span className="ms-2 float-end">
              {isOpen ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </span>
          </button>
        </div>
        <div className="card-body collapse" id="collapseExample12">

        {employmenttype.map((employmenttype, index) => (
          <div className="form-check mb-3" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`employmenttype-${index}`}
              value={employmenttype}
              checked={selectedEmploymenttypes.includes(employmenttype)}
              onChange={handleEmploymentChange}
            />
            <label className="form-check-label ms-2" htmlFor={`employmenttype-${index}`}>
              {employmenttype}
            </label>
          </div>
        ))}
      </div>
      </div>


      <div class="card shadow-sm mt-4 bx-center border-0 p-4">
        <div class="card-header">
          <button
            className="btn w-100 btn-block text-start py-0 px-0 mx-0 fw-medium fs-5 border-0"
            type="button"
            onClick={() => toggleCollapse('collapseExample14')}
            aria-expanded={isOpen}
            aria-controls="collapseExample2"
          >
           Experience Level

            <span className="ms-2 float-end">
              {isOpen ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </span>
          </button>
        </div>
        <div className="card-body collapse" id="collapseExample14">

        {experiencelevel.map((experiencelevel, index) => (
          <div className="form-check mb-3" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`experiencelevel-${index}`}
              value={experiencelevel}
              checked={selectedExperiencelevel.includes(experiencelevel)}
              onChange={handleExperienceChange}
            />
            <label className="form-check-label ms-2" htmlFor={`experiencelevel-${index}`}>
              {experiencelevel}
            </label>
          </div>
        ))}
      </div>
      </div>






    <div class="card shadow-sm mt-4 bx-center border-0 p-4">
        <div class="card-header">
          <button
            className="btn w-100 btn-block text-start py-0 px-0 mx-0 fw-medium fs-5 border-0"
            type="button"
            onClick={() => toggleCollapse('collapseExample1')}
            aria-expanded={isOpen}
            aria-controls="collapseExample1"
          >
            Notice Period

            <span className="ms-2 float-end">
              {isOpen ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </span>
          </button>
        </div>


        <div className="card-body collapse" id="collapseExample1">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="noticePeriod"
              id="notice30days"
              value="30 days"
              checked={selectedOption === '30 days'}
              onChange={handleOptionChange} />
            <label className="form-check-label ms-2" htmlFor="notice30days">
              30 Days
            </label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="noticePeriod"
              id="notice45days"
              value="45 days"
              checked={selectedOption === '45 days'}
              onChange={handleOptionChange} />
            <label className="form-check-label ms-2" htmlFor="notice45days">
              45 Days
            </label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="noticePeriod"
              id="notice60days"
              value="60 days"
              checked={selectedOption === '60 days'}
              onChange={handleOptionChange} />
            <label className="form-check-label ms-2" htmlFor="notice60days">
              60 Days
            </label>
          </div>
        </div>

      </div>







      </>
  );
};

export default SearchFilter;