import React from 'react';
import PropTypes from 'prop-types';

// Define the TwoCol component
const TwoCol = ({ twoCol }) => {
  const { title, description, imgUrl, className, versa } = twoCol;

  return (
    <div className={`mt-5 ${className}`}>
      <div className="row justify-content-center">
        {versa ? (
          <>
            <div className="col-md-5 d-flex align-items-center justify-content-center">
              <div className="col-lg-10 text-lg-start text-md-start text-xl-start text-center">
                <h3 className="card-title display-6 fw-semibold pb-3">{title}</h3>
                {description && <p className="fs-5 card-text">{description}</p>}
              </div>
            </div>
            <div className="col-md-7 d-flex justify-content-center d-none d-md-block">
              {typeof imgUrl === 'string' ? (
                <img className="card-img-top col-img" src={imgUrl} alt={title} />
              ) : (
                imgUrl
              )}
            </div>
          </>
        ) : (
          <>
            <div className="col-md-7 d-flex justify-content-center d-none d-md-block">
              {typeof imgUrl === 'string' ? (
                <img className="card-img-top col-img" src={imgUrl} alt={title} />
              ) : (
                imgUrl
              )}
            </div>
            <div className="col-md-5 d-flex align-items-center justify-content-center">
            <div className="col-lg-10 text-lg-start text-md-start text-xl-start text-center">
                <h3 className="card-title display-6 fw-semibold pb-3">{title}</h3>
                {description && <p className="fs-5 card-text">{description}</p>}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

// Define PropTypes for type-checking
TwoCol.propTypes = {
  twoCol: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    imgUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]),
    className: PropTypes.string.isRequired,
    versa: PropTypes.bool.isRequired
  }).isRequired
};

export default TwoCol;