import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AutocompleteDropdown from '../AutocompleteDropdown/AutocompleteDropdown';
import { MdModeEdit } from "react-icons/md";
import ActivePost from '../ActivePost/ActivePost';
import InActivePost from '../InActivePost/InActivePost';

export const JobPosts = () => {
  const [activeTab, setActiveTab] = useState('activepost');

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="js-pg-hg">
    <div className="container">
    <div className="d-lg-flex d-sm-block flex-row align-items-center mb-4 justify-content-between">
                    <div className='js-mn-title flex-grow-1'>
                        <h2 className='fw-bold pb-2'>Posted Jobs</h2>
                        <p>You have active 10 jobs posts. </p>
                    </div>
                    <div className="mt-0">
                        <Link to="/postjob"
                                    title="Psot a Job" className='btn btn-outline-primary d-block mb-3 mb-lg-0 mb-xl-0 mt-2 ms-lg-4 px-4 py-3'><MdModeEdit className='me-2' /> Post a Job</Link>
                    </div>
                </div>

      {/* Tab Navigation */}
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'activepost' ? 'active' : ''}`}
            id="activepost-tab"
            onClick={() => handleTabChange('activepost')}
          >
            Active Post
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'inactivepost' ? 'active' : ''}`}
            id="inactivepost-tab"
            onClick={() => handleTabChange('inactivepost')}
          >
            Inactive Post
          </button>
        </li>
      </ul>

      <div className="tab-content" id="myTabContent">
        <div className={`tab-pane pt-4 mt-4 fade ${activeTab === 'activepost' ? 'show active' : ''}`} id="activepost" role="tabpanel">
            <ActivePost />
        </div>

        <div className={`tab-pane pt-4 mt-4 fade ${activeTab === 'inactivepost' ? 'show active' : ''}`} id="inactivepost" role="tabpanel">
        <InActivePost />
        </div>
      </div>
    </div>
    </div>
  );
};

export default JobPosts;