import React from 'react';
import { Link } from 'react-router-dom';
import JobCreationForm from '../JobCreationForm/JobCreationForm';
import AutocompleteDropdown from '../AutocompleteDropdown/AutocompleteDropdown';

const PostJob = () => {
  return (

    <div className="js-pg-hg">
      <div className="container">
      <div className="d-lg-flex d-sm-block flex-row align-items-center justify-content-between">
          <div className='js-mn-title flex-grow-1'>
            <h2 className='fw-bold pb-2'>Post a Job</h2>
            <p>Please fill below form to publish job post.</p>
          </div>
        </div>
      </div>
      <JobCreationForm />
    </div>
  );
};

export default PostJob;
