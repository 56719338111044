import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaRegEyeSlash } from "react-icons/fa";

export const AccountSettings = () => {
  const [activeTab, setActiveTab] = useState('activepost');

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="js-pg-hg">
      <div className="container">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className='js-mn-title flex-grow-1'>
            <h2 className='fw-bold pb-2'>Account Settings</h2>
            <p>Edit Profile and update change </p>
          </div>
        </div>

        {/* Tab Navigation */}
        <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === 'activepost' ? 'active' : ''}`}
              id="activepost-tab"
              onClick={() => handleTabChange('activepost')}
            >
              Edit Profile
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === 'inactivepost' ? 'active' : ''}`}
              id="inactivepost-tab"
              onClick={() => handleTabChange('inactivepost')}
            >
              Change Password
            </button>
          </li>
        </ul>

        <div className="tab-content row mb-5" id="myTabContent">
          <div className={`tab-pane col-lg-6 mt-4 fade ${activeTab === 'activepost' ? 'show active' : ''}`} id="activepost" role="tabpanel">
            <div className="form-step fade-in">
              <div className="d-flex flex-row align-items-center mb-4 justify-content-between">
                <div className='js-mn-title flex-grow-1 mt-3'>
                  <h4 className='fs-5 pb-2'>Personl Information</h4>
                </div>
              </div>
              <div className="mb-4">
                <label className="form-label">Full Name</label>
                <input
                  type="text" value="Madhav R"
                  className="form-control" />
              </div>
              <div className="mb-4">
                <label className="form-label">Email Address</label>
                <input
                  value="madhav.k@veritis.com"
                  type="email"
                  className="form-control"

                />
              </div>

              <div className="mb-4">
                <label className="form-label">Profile Image</label>
                <input
                  type="file"
                  className="form-control"
                />
              </div>

              <div className="d-flex flex-row align-items-center mb-4 justify-content-between">
                <div className='js-mn-title flex-grow-1 mt-3'>
                  <h4 className='fs-5 pb-2'>Company Information</h4>
                </div>
              </div>
              <div className="mb-4">
                <label className="form-label">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  value="Veritis Group Inc"
                  disabled/>
              </div>
              <div className="mb-4">
                <label className="form-label">Company Website (optional)</label>
                <input
                  type="url"
                  className="form-control"
                  placeholder="https://veritis.com"
                  disabled/>
              </div>


              <button type="button" className="btn mt-3 px-5 py-3 btn-outline-primary me-4">
                Cancel
              </button>
              <button type="button" className="btn mt-3 px-5 py-3 btn-primary">
                Update
              </button>
            </div>

          </div>

          <div className={`tab-pane mt-4 col-lg-6 fade ${activeTab === 'inactivepost' ? 'show active' : ''}`} id="inactivepost" role="tabpanel">
          <div className="d-flex flex-row align-items-center mb-4 justify-content-between">
                <div className='js-mn-title flex-grow-1 mt-3'>
                  <h4 className='fs-5 pb-2'>Update Password</h4>
                </div>
              </div>
          <div className='mb-4'>
          <label className="form-label">New Password</label>
          <div className="input-group">
      <input className="form-control m-0"
        type="password"
        placeholder="Enter your password"
      />
      <button type='button' className="toggle-icon input-group-text">
      <FaRegEyeSlash />
      </button>
    </div>
    </div>
    <div className='mb-4'>
    <label className="form-label">Confirm Password</label>
    <div className="input-group mb-4">
      <input className="form-control m-0"
        type="password"
        placeholder="Enter your password"
      />
      <button type='button' className="toggle-icon input-group-text">
      <FaRegEyeSlash />
      </button>
    </div>
    </div>

    <button type="button" className="btn mt-3 px-5 py-3 btn-primary">
                Update
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;