import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import JobProfileList from '../JobProfiles/JobProfiles';
import SearchFilter from '../SearchFilter/SearchFilter';

const ProfileList = () => {

  return (
    <div className="js-pg-hg">
      <div className="container">
        <div className="row justify-content-center">
          <div class="col-lg-4 col-md-4 col-sm-4 col-12 mb-4">
          <SearchFilter />
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 col-12">
          <JobProfileList />
          </div>
        </div>
      </div>

      <div>

    </div>
    </div>


  );
};

export default ProfileList;