import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import Scrollbar from 'react-scrollbar';



const Notification = ({ isOpen, onClose }) => {
  const handleItemClick = () => {
    onClose(); // Close the menu when an item is clicked
  };

  return (
    <Menu className='menubar' right isOpen={isOpen} onClose={onClose}>
      <div className='d-flex justify-content- border-bottom py-4 px-3 align-items-center vertical-bottom'>
        <div className='p-3 pb-3 pt-4'>
          <h4>Reminders</h4>
        </div>
        <div>
        </div>

      </div>
      <Scrollbar
        speed={0.8}
        className="custom-scrollbar"
        horizontal={false}
      >
        <p className='p-0 mx-4 px-1'>
          <div className='border-bottom d-block p-4 px-0'>
          <div className='d-flex justify-content-between px-0 py-4 pt-2'>
            <div className="card-text small">Today</div>
          </div>
          <div className='d-flex'>
            <div className="card-text small text-start">
              <label className='text-secondary pb-1'>Reminder at <strong className='text-success'>3:40 PM</strong></label>
              <p>For interview follow up <strong className='text-body'>Karthik</strong></p>
              <button className='btn btn-small p-0 m-0 text-decoration-none btn-outline-primary small px-2 py-1'>Send Now</button>
              <button className='btn p-0 m-0 ms-4 text-decoration-none btn-link'>Not Now</button>
            </div>
          </div>
          </div>

          <div className='border-bottom d-block p-4 px-0'>
          <div className='d-flex'>
            <div className="card-text small text-start">
            <label className='text-secondary pb-1'>Reminder at <strong className='text-success'>4:30 PM</strong></label>
              <p>For interview call follow up <strong className='text-body'>Karunakar</strong></p>
              <button className='btn btn-small p-0 m-0 text-decoration-none btn-outline-primary small px-2 py-1'>Send Now</button>
              <button className='btn p-0 m-0 ms-4 text-decoration-none btn-link'>Not Now</button>
            </div>
          </div>
          </div>


          <div className='border-bottom d-block p-4 px-0'>
          <div className='d-flex'>
            <div className="card-text small text-start">
            <label className='text-secondary pb-1'>Reminder at <strong className='text-success'>5:00 PM</strong></label>
              <p>Send job description to <strong className='text-body'>Vinay</strong></p>
              <button className='btn btn-small p-0 m-0 text-decoration-none btn-outline-primary small px-2 py-1'>Send Now</button>
              <button className='btn p-0 m-0 ms-4 text-decoration-none btn-link'>Not Now</button>
            </div>
          </div>
          </div>

          <div className='border-bottom d-block p-4 px-0'>
          <div className='d-flex'>
            <div className="card-text small text-start">
            <label className='text-secondary pb-1'>Reminder at <strong className='text-success'>5:45 PM</strong></label>
              <p>Send job description to <strong className='text-body'>Madhav</strong></p>
              <button className='btn btn-small p-0 m-0 text-decoration-none btn-outline-primary small px-2 py-1'>Send Now</button>
              <button className='btn p-0 m-0 ms-4 text-decoration-none btn-link'>Not Now</button>
            </div>
          </div>
          </div>


          <div className='border-bottom d-block p-4 px-0'>
          <div className='d-flex'>
            <div className="card-text small text-start">
            <label className='text-secondary pb-1'>Reminder at <strong className='text-success'>6:00 PM</strong></label>
              <p>For interview call follow up <strong className='text-body'>Manoj</strong></p>
              <button className='btn btn-small p-0 m-0 text-decoration-none btn-outline-primary small px-2 py-1'>Send Now</button>
              <button className='btn p-0 m-0 ms-4 text-decoration-none btn-link'>Not Now</button>
            </div>
          </div>
          </div>

        </p>

      </Scrollbar>
    </Menu>
  );
};

export default Notification;
