import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';

const Header = () => {
    return (
      <Navbar expand="lg" className="py-4 nav-bg border-0">
        <Container>
          <Navbar.Brand as={Link} to="/" className="fs-3 fw-bold">Jobsco</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ms-auto mb-2 mb-lg-0">
              <Nav.Item>
                <Button variant="outline-primary" className="me-4 px-5 py-3 bg-white text-primary rounded-5">Sales Enquiry</Button>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };

  export default Header;